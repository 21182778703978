import { put, delay, call, fetch } from 'redux-saga/effects'; //helper function delay
import axios from 'axios';

import * as actions from '../actions/index';

//its a generator function - helps to wait using yeild
export function* logoutSaga( action ) {
    console.log('[sagas auth] logoutSaga...');
    // yield call( [localStorage, 'removeItem'], 'token' ); //this will help in mock and userful for testing
    // yield call( [localStorage, 'removeItem'], 'expirationDate' );
    // yield call( [localStorage, 'removeItem'], 'userId' );

    yield localStorage.removeItem('token');
    yield localStorage.removeItem('expirationDate');
    yield localStorage.removeItem('userId');
    yield put(actions.logoutSucceed());
}

export function* checkAuthTimeoutSaga(action) {
    console.log('-----[sagas auth] checkAuthTimeoutSaga');
    yield delay(action.expirationTime * 1000);
    console.log('1000 * ', action.expirationTime);
    yield put(actions.logout());

}

export function* authUserSaga(action) {
    console.log('[sagas auth] authUserSaga');
    yield put(actions.authStart());
    const authData = {
        mobile: action.email,
        password: action.password,
        returnSecureToken: true
    }; 
    let url = 'https://app.milksankalan.in/auth/sign-up?key=AIzaSyBpL8sun_JU_Mffb1sO9DI7owzE2uxGvPc';

    if(!action.isSignup) {
        url = 'https://app.milksankalan.in/auth/sign-in-with-password?key=AIzaSyBpL8sun_JU_Mffb1sO9DI7owzE2uxGvPc';
    }
    try {
        const response = yield axios.post(url, authData);

        const expirationDate = yield new Date( new Date().getTime() + response.data.expiresIn * 1000 );
        
        yield localStorage.setItem('token', response.data.idToken);
        yield localStorage.setItem('expirationDate', expirationDate);
        yield localStorage.setItem('userId', response.data.localId);
        yield put(actions.authSuccess(response.data.idToken, response.data.localId));
        yield put(actions.checkAuthTimeout(response.data.expiresIn));
        yield put(actions.setAuthRedirectPath('/'));
    } catch( error ) {
        yield put(actions.authFail({message: 'Network Error!!'}));
    }
}

export function* authCheckStateSaga() {
    console.log('[auth.js] store/sagas authCheckStateSaga');
    const token = yield localStorage.getItem('token');
    console.log('[auth.js] store/sagas authCheckStateSaga token', token);
    
    if(!token) {
        yield put(actions.logout());
    } else {
        const expirationDate = yield new Date( localStorage.getItem('expirationDate') );
        console.log(expirationDate);
        console.log(new Date());
        if( expirationDate.getTime() > new Date().getTime() ) {
            const userId = yield  localStorage.getItem('userId');
            const timeout = ( expirationDate.getTime() - new Date().getTime() ) /1000;
            yield put( actions.authSuccess( token, userId ) );
            
            yield put( actions.checkAuthTimeout( timeout ) );
        } else {
            yield put(actions.logout());
        }
    }
}