//takeEvery - will allow us to listen certain action and do something they occur
import { takeEvery } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { logoutSaga, checkAuthTimeoutSaga, authUserSaga, authCheckStateSaga } from './auth';
import { fetchMilkSlipsSaga } from './milkSlip';

export function* watchAuth() {
    console.log('[saga index] watchAuth');
    yield takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga);
    yield takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga);
    yield takeEvery(actionTypes.AUTH_USER, authUserSaga);
    yield takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga);
    console.log('[saga index] watched');
}

export function* watchMilkSlip() {
    console.log('[saga index] watchMilkSlip');
    yield takeEvery( actionTypes.FETCH_MILK_SLIPS, fetchMilkSlipsSaga );
    console.log('[saga index] watched');
}