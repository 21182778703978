import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    isLoading: false,
    error: null,
    isSuccess: false,
    slipsData: [],
    chartData: []
};

const fetchMilkSlipsStart = ( state, action ) => {
    return updateObject( state, { isLoading: true } );
}

const fetchMilkSlipsSuccess = ( state, action ) => {
    return updateObject( state, { isLoading: false, isSuccess: true, slipsData: action.slipsData } );
}

const fetchMilkSlipsFail = ( state, action ) => {
    return updateObject( state, { isLoading: false, error: action.error } );
}

const milkSlip = ( state = initialState, action ) => {
    console.log('[reducer] milkSlip', action.type);
    switch( action.type ) {
        case actionTypes.FETCH_MILK_SLIPS_START: return fetchMilkSlipsStart( state, action );
        case actionTypes.FETCH_MILK_SLIPS_SUCCESS: return fetchMilkSlipsSuccess( state, action );
        case actionTypes.FETCH_MILK_SLIPS_FAIL: return fetchMilkSlipsFail( state, action );
        default:
        return state;
    }
}

export default milkSlip;
