import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';


import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import authReducer from './store/reducers/auth';
import milkSlipReducer from './store/reducers/milkSlip';

import { watchAuth, watchMilkSlip } from './store/sagas/index';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    milkSlip: milkSlipReducer
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore( rootReducer, composeEnhancers( applyMiddleware( sagaMiddleware ) ) );

sagaMiddleware.run( watchAuth );
sagaMiddleware.run( watchMilkSlip );

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
