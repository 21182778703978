
import React from 'react';

import classes from './Navigationitems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = ( props ) => (
    <ul className={classes.NavigationItems}>
        {props.isAuthenticated ? <NavigationItem url="/" >Dashboard</NavigationItem> : null}
        {props.isAuthenticated ? <NavigationItem url="/passbook" >Passbook</NavigationItem> : null}
        {props.isAuthenticated ? <NavigationItem url="/report" >Report</NavigationItem> : null}
        {props.isAuthenticated ? <NavigationItem url="/milk-slip" >Milk Slip</NavigationItem> : null}
        {props.isAuthenticated ? <NavigationItem url="/notifications" >Notifications</NavigationItem> : null}
        <NavigationItem url="/about-us" >About Us</NavigationItem>
        {!props.isAuthenticated
            ? <NavigationItem url="/auth" >Sign In</NavigationItem>
            : <NavigationItem url="/logout" >Logout</NavigationItem>}
        
    </ul>
);

export default navigationItems;