import { put } from 'redux-saga/effects';

import axios from 'axios';
import * as actions from '../actions';

export function* fetchMilkSlipsSaga( action ) {
    console.log('[fetchMilkSlips saga]',action);

    yield put( actions.fetchMilkSlipsStart() );
     const queryParams = '?auth=' + action.token + '&equalTo=' + action.userId;
     const authData = {
        key: 'AIzaSyBpL8sun_JU_Mffb1sO9DI7owzE2uxGvPc'
    };
    try {
         const response = yield axios.post('https://app.milksankalan.in/reports/milk-slips' + queryParams, authData );
        const milkSlips = [];
                     for ( let key in response.data ) {
                         milkSlips.push({ 
                            ...response.data[key],
                             id:key
                          });
                     }

        yield put( actions.fetchMilkSlipsSuccess( milkSlips ) );
    } catch ( error ) {
        yield put( actions.fetchMilkSlipsFail( error ) );
    }
}