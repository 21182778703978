import React, {Component} from 'react';

const asyncComponent = ( importComponent ) => {
    return class extends Component { //anonnymous class component
        state = {
            component: null
        }
        
        componentDidMount() {
            console.log('[async componentDidMount]');
            importComponent()
                .then(cmp => {
                    this.setState({component: cmp.default});
                });
        }
        
        render() {
            console.log('[async render]');
            const C = this.state.component;

            return C ? <C {...this.props} /> : null;
        }
    }
}

export default asyncComponent;