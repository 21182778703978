import * as actionTypes from './actionTypes';

export const fetchMilkSlips = ( token, userId ) => {
    console.log('[Milk Slip] action fetchMilkSlips');
    return {
        type: actionTypes.FETCH_MILK_SLIPS,
        token: token,
        userId: userId
    }
}

export const fetchMilkSlipsStart = () => {
    return {
        type: actionTypes.FETCH_MILK_SLIPS_START
    }
}

export const fetchMilkSlipsSuccess = ( milkSlipsData ) => {
    return {
        type: actionTypes.FETCH_MILK_SLIPS_SUCCESS,
        slipsData: milkSlipsData
    }
}

export const fetchMilkSlipsFail = ( error ) => {
    return {
        type: actionTypes.FETCH_MILK_SLIPS_FAIL,
        error: error
    }
}