import *  as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    authRedirectPath: '/'
}

const authStart = (state, action) => {
    console.log('[reducer] authStart');
    return updateObject( state, { error: null, loading:true } );
};   

const authFail = (state, action) => {
    console.log('[reducer] authFail', action.error);
    return updateObject( state, { error: action.error, loading:false } );
}; 

const authSucess = (state, action) => {
    console.log('[reducer] authSucess');
    return updateObject( state, { 
        token: action.idToken,
        userId: action.userId,
        error: action.error, 
        loading:false } );
}

const authLogout = ( state, action ) => {
    return updateObject( state, {
        token: null,
        userId: null,
    });
}

const setAuthRedirectPath = ( state, action ) => {
    return updateObject( state, { authRedirectPath: action.path } );
}

const auth = (state = initialState, action) => {
    console.log('[reducer] auth', action.type);
    switch(action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSucess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath( state, action );
        default:
            return state;
    }
}

export default auth;