export const AUTH_USER = 'AUTH_USER';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';

export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

//Dashboard
export const FETCH_MILK_SLIPS = 'FETCH_MILK_SLIPS';
export const FETCH_MILK_SLIPS_START = 'FETCH_MILK_SLIPS_START'; 
export const FETCH_MILK_SLIPS_SUCCESS = 'FETCH_MILK_SLIPS_SUCCESS';
export const FETCH_MILK_SLIPS_FAIL = 'FETCH_MILK_SLIPS_FAIL';