import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import './App.css';
import Layout from './hoc/Layout/Layout';

import * as actions from './store/actions/index';
import asyncComponent from './hoc/asyncComponent/asyncComponent';

const asyncAuth = asyncComponent(() => {
  return import('./containers/Auth/Auth');
});

const asyncDashboard = asyncComponent(() => {
  return import('./containers/Dashboard/Dashboard');
});

const asyncLogout = asyncComponent(() => {
  return import('./containers/Logout/Logout');
});

class App extends Component {

  componentDidMount() {
    console.log('[App] componentDidMount - onTryAutoSignup');
    this.props.onTryAutoSignup();

    if( !this.props.isAuth ) {
      this.props.onSetAuthFailPath();
    }
  }
  render() {

    let routes = null;
    if(this.props.isAuth) {
      routes = <Switch>
        <Route path="/report" component="" />
        <Route path="/milk-slip/:id" component="" />
        <Route path="/logout" component={asyncLogout} />
        <Route path="/auth" component={asyncAuth} />
        <Route path="/" exact component={asyncDashboard} />
      </Switch>
    } else {
      routes = <Switch>
            <Route path="/auth" component={asyncAuth} />
            <Route path="/" exact component="" />
            <Redirect to="/" />
          </Switch>
    }

    let autoRedirect = null;
    if( !this.props.isAuth ) {
      console.log(this.props.redirectPath);
      autoRedirect = <Redirect to={{pathname:this.props.redirectPath}}/>;
    }

    return (
      <div className="App">
      {autoRedirect}
        <Layout>
           {routes} 
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log('[App] mapStateToProps');
  return {
    isAuth: state.auth.token !== null,
    redirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchToProps = dispatch => {
  console.log('[App] mapDispatchToProps');
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onSetAuthFailPath: () => dispatch( actions.setAuthRedirectPath( '/auth' ) )
  }
}

export default withRouter(connect( mapStateToProps, mapDispatchToProps)( App ));
